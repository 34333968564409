<template>
<div id="" class="justify">
  <div class="search-row">
    <Navbar navbarClass="transparent"></Navbar>
      <v-container grid-list-xs,sm,md,lg,xl>
        <v-layout  wrap justify-center>
          <h1 class="display-3 blog-title">
            {{$t('aboutUs.title')}}
          </h1>
        </v-layout>
    </v-container>
  </div>
  <v-container grid-list-xl>
    <v-layout  wrap justify-center>
      <v-flex md8 sm9 xs10 >
        <h3 class="title about-us">{{$t('aboutUs.subTitle')}}</h3>
      </v-flex>
    </v-layout>
    <v-layout  wrap justify-center>
      <v-flex md8 sm9 xs10 >
        <v-layout  wrap>
          <v-flex md6 xs12>
            <div>
              {{$t('aboutUs.t1')}}
            </div>
          </v-flex>
          <v-flex md6 xs12>
            <img src="https://res.cloudinary.com/my-room-abroad/image/fetch/q_auto,fl_lossy,f_auto/https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/family2.jpg" alt="My Room Abroad best housing for internationals in Taiwan and Japan" class="family2 md-right">
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout justify-center  wrap>
      <v-flex md8 sm9 xs10>
        <div class="">
          {{$t('aboutUs.t2')}}
        </div>
      </v-flex>
      <v-flex md8 sm9 xs10>
        <div>
          {{$t('aboutUs.t3')}}
        </div>
      </v-flex>
      <v-flex md8 sm9 xs10>
        <div>
          {{$t('aboutUs.t4')}}
        </div>
      </v-flex>
      <v-flex md8 sm9 xs10>
        <v-layout  wrap>
          <v-flex xs12 md6>
            <img src="https://res.cloudinary.com/my-room-abroad/image/fetch/q_auto,fl_lossy,f_auto/https://res.cloudinary.com/my-room-abroad/image/upload/v1537776832/blog/family.jpg" alt="My Room Abroad best housing for internationals in Taiwan and Japan" class="family ">
          </v-flex>
          <v-flex xs12 md6>
            <div class="">
              {{$t('aboutUs.t5')}}
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs10 sm9 md8>
        <div>
          {{$t('aboutUs.t6')}}
        </div>
      </v-flex>
    </v-layout>

  </v-container>
</div>
</template>
<script>
export default {
  name: "",
  data: () => ({

  }),
  created() {
    //do something after creating vue instance
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })

  }
}
</script>
<style lang="scss" scoped>
.search-row {
    background: rgba(255,255,255,0),;
    background: rgba(102,102,102,0.5), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779176/blog/teamwork.jpg") bottom/cover no-repeat;
    background: -moz-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779176/blog/teamwork.jpg") bottom/cover no-repeat;
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(102,102,102,0.5)), color-stop(40%, rgba(133,133,133,0)), color-stop(100%, rgba(255,255,255,0))), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779176/blog/teamwork.jpg") bottom/cover no-repeat;
    background: -webkit-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779176/blog/teamwork.jpg") bottom/cover no-repeat;
    background: -o-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779176/blog/teamwork.jpg") bottom/cover no-repeat;
    background: -ms-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779176/blog/teamwork.jpg") bottom/cover no-repeat;
    background: linear-gradient(to bottom, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779176/blog/teamwork.jpg") bottom/cover no-repeat;
}
.blog-title {
    padding-top: 15vh;
    margin-bottom: 10vh;
    color: #ffffff;
    text-shadow: 0 2px 4px rgba(0,0,0,.6);
}
.about-us div {
    margin: 1rem 0;
    text-align: justify;
}
.family{
  width:90%;
}
.family2 {
    width: 90%;
    margin: auto;
}
.justify {
  text-align: justify;
}
.nomargin {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
</style>
